import React from 'react'
import Layout from '../../components/Layout'

export default class Index extends React.Component {
  render() {
    return (
      <Layout>
        <section className="section">
          <div className="container">
            <div className="content">
              <h1>Kontakt</h1>
              <h2>Jan Všetečka</h2>
              <p>
                <strong>IČ: </strong>07912293<br/>
                <strong>Telefon: </strong><a href="tel:+420774747825">+420774747825</a><br/>
                <strong>E-mail: </strong><a href="mailto:jan@zahradyvsetecka.cz">jan@zahradyvsetecka.cz</a><br/>
                <strong>Adresa: </strong>
                Revoluční 11<br/>
                Brno-Tuřany 620 00 <br/>
                Brno<br/>
              </p>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
